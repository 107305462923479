<template>
  <div id="permissions-audits">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <h5>Nombre Permiso</h5>
            <b-row>
              <b-col xl="3" sm="12">
                <b-form-input id="name"
                  v-model="namePermission"
                  name="name"
                  disabled />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <permissions-audits-list :audits="audits"></permissions-audits-list>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import PermissionsAuditsList from './components/PermissionsAuditsList'

  export default{
    name: 'PermissionsAuditsView',
    components: {PermissionsAuditsList},
    props: ['audits'],
    data() {
      return {
      }
    },
    computed: {
      namePermission () {
        return this.audits ? this.audits.name : ''
      }
    }
  }
</script>